import { gql } from "graphql-request";
import { gqlClient } from "./client";
// import { serialize } from "next-mdx-remote/serialize";

const { SITE_URL } = process.env;

const postsQuery = gql`
  query GetPosts {
    posts(orderBy: createdAt_DESC) {
    id
    intro
    title
    slug
    updatedAt
    author {
      name
      avatar {
        url
      }
    }
    coverPhoto {
      url
    }
    article {
      html
    }
  }
  }
`;

const postsSlugQuery = gql`
  query GetPostsSlug {
    posts {
        slug
    }
  }
`;
// posts(orderBy: createdAt_DESC) {
//     title
//   }

const postQuery = gql`
  query Post($slug : String!) {
    posts(where: { slug: $slug } ) {
    createdAt
    id
    intro
    publishedAt
    title
    updatedAt
    author {
      name
      id
      avatar {
        url
      }
    }
    coverPhoto {
      url
    }
    article {
      html
    }
  }
  }
`;

type GetPostByUrlProps = {
  slug: string
}

type CoverPhoto = {
  url: string
}

// type Author = {
//     name: string
// }

// type PostBody = {
//     html: string
// }

export type PostT = {
  createdAt?: string
  id: string
  intro: string
  publishedAt: string
  title: string
  updatedAt?: string
  slug: string
  coverPhoto: CoverPhoto,
  article: any
}

const parsePost = (post: PostT): PostT => {
    const {
        coverPhoto,
        ...rest
    } = post;

    const photo = coverPhoto ? coverPhoto : {
        url: "/static/cover.jpg"
    };

    return {
        ...rest,
        coverPhoto: photo
    };
};

export const getAllPosts = async (): Promise<{ posts: PostT[] }> => {
    const { posts } = await gqlClient.request(postsQuery);

    return { posts: posts.map(parsePost) };
};

export const getAllPostsSlug = async (): Promise<string[]> => {
    const { posts = [] } = await gqlClient.request(postsSlugQuery);

    return posts.map(({ slug }: { slug: string }) => `${slug}`);
};

export const getAllPostsSitemap = async (): Promise<[{
  loc: string,
  lastmod: string
}]> => {
    const { posts = [] } = await gqlClient.request(postsQuery);

    return posts?.map(({ updatedAt, slug }: PostT) => ({
        loc: `${SITE_URL}/blog/${slug}`,
        lastmod: updatedAt
    }));
};


export const getPostBySlug = async ({
    slug
}: GetPostByUrlProps): Promise<PostT> => {
    const { posts } = await gqlClient.request(postQuery, { slug });

    return parsePost(posts?.[0]);
};
