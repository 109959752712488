import Image from "next/image";
import Link from "next/link";
import { PostT } from '@/utils/graphql/post';
import s from './styles/BlogListItem.module.scss'
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import useTranslation from "next-translate/useTranslation";
import { Button } from "../Form";

type Props = {
    post: PostT,
    withIntro?: boolean
}

const BlogListItem = ({
    //@ts-ignore
    post: {
        intro,
        title,
        slug,
        //@ts-ignore
        coverPhoto: {
            url: photoUrl = ''
        } = {}
    } = {},
    withIntro = true
}: Props): JSX.Element => {
    const { t } = useTranslation();

    return <Link href={`/blog/${slug}`}>
        <a>
            <div className={s.blogListItem}>
                <div className={s.blogItemPhoto}>
                    {photoUrl && <Image
                        src={photoUrl}
                        alt=""
                        layout="fill"
                    />}
                    <div className={s.blogItemTitle}>
                        {title}
                    </div>
                </div>
                {withIntro && <div className={s.blogItemIntro}>
                    {intro}

                    <Button
                        kind="link"
                    >
                        {t("blog:readMore")}<HiOutlineArrowNarrowRight />
                    </Button>
                </div>}
            </div>
        </a>
    </Link>
}

export default BlogListItem