
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from "react";
import {  NextHead, WebsiteIntro } from "@/c/Layout";
import { CarsAndCostsT } from "@/types";
import { fetchFilteredCars } from "@/utils/getters";
import { SearchForm } from "@/c/Form";
import { CarCard } from "@/c/Car";

import styles from "../styles/pages/Common.module.scss";
import { ListDivider } from "@/c/Blog";
import config from "@/config";

type Props = {
    cars: CarsAndCostsT[]
}

const {
    flags: {
        isBlog = false
    } = {}
} = config;

const Home = ({ cars }: Props) => {
    const carTopList = cars?.slice(0, 2) || [];
    const carBottomList = cars?.slice(2) || [];

    return <div>
        <NextHead
            page="MAIN"
        />
        <WebsiteIntro />
        <div className={styles.searchWrapper}>
            <SearchForm />
        </div>
        <div className={styles.list}>
            {
                carTopList?.map(({ car, costs }) => <CarCard
                    key={car.id}
                    car={car}
                    costs={costs}
                />)
            }
        </div>
        {isBlog && <ListDivider />}
        <div className={styles.list}>
            {
                carBottomList?.map(({ car, costs }) => <CarCard
                    key={car.id}
                    car={car}
                    costs={costs}
                />)
            }
        </div>
    </div>;
};


 async function _getServerSideProps() {
    const cars = await fetchFilteredCars({});

    return {
        props: {
            cars,
        }
    };
}

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  