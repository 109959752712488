import { getAllPosts, PostT } from '@/utils/graphql/post';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { Header, Section } from '../Layout';
import BlogListItem from './BlogListItem';
import s from './styles/ListDivider.module.scss'

type Props = {
}

const ListDivider = ({ }: Props) => {
    const [posts, setPosts] = useState<PostT[]>();

    const fetchPosts = async () => {
        const { posts: fetchedPosts } = await getAllPosts();

        setPosts(fetchedPosts?.slice(0, 2))
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    if (isEmpty(posts)) return <></>

    console.log('posts', posts)

    return <div className={s.listDivider}>
        <Section
            color="grey"
            withPaddingsY
        >
            <div className={s.list}>
                <Header variant="h2">
                    Blog
                </Header>
            </div>

            <div className={s.list}>

                {
                    posts?.map(post => <BlogListItem
                        withIntro={false}
                        post={post} />)
                }</div>
        </Section>
    </div>;
}

export default ListDivider